
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import PickerInputMixin from "@/mixins/PickerInputMixin.vue";

@Component({
  components: {}
})
export default class DatabasePicker extends Mixins(PickerInputMixin) {
  @Prop() action!: string;
  @Prop() itemText!: string;
  @Prop() autoComplete: any;
  @Prop() placeHolder: string;
  @Prop() disabled: any;
  @Prop() defaultLast: any;
  @Prop() hideDetails!: string | boolean;
  @Prop() defaultId !: number;
  @Prop() readonly !: string | boolean;
  @Prop() noDataText!: string;
  @Prop() filterFunction!: Function;

  localItemText: string = "";
  isLoading = false;

  get isAutoComplete() {
    return this.autoComplete || this.autoComplete === "";
  }

  get isDisabled() {
    return this.disabled || this.disabled === "";
  }

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }

  get isReadonly() {
    return this.readonly || this.readonly === "";
  }

  @Watch("action")
  onActionChange() {
    this.load();
  }

  @Watch("filterFunction")
  onFilterFunctionChange() {
    this.load();
  }

  @Watch('selectedItem')
  onSelectedItemChange() {
    if (this.selectedItem?.id) {
      this.$emit('selected-item', this.selectedItem);
    }
  }

  @Watch('defaultId')
  onDefaultIdChange() {
    if (this.defaultId && this.items)
      this.items.forEach((item) => {
        if (item.id == this.defaultId) this.selectedItem = item;
      })
  }

  mounted() {
    this.localItemText = this.itemText == null ? "isim" : this.itemText;
    this.load();
  }

  load() {
    if (this.action && this.action != "") {
      this.isLoading = true;
      this.$http.get(this.action).then((response) => {
        if (this.filterFunction) {
          this.items = this.filterFunction(response.data);
        } else {
          this.items = response.data.hasOwnProperty("data") ? response.data.data : response.data;
        }
        if (this.defaultLast) {
          this.selectedItem = this.items.at(-1);
          this.input();
        } else if (this.defaultId) {
          this.items.forEach((item) => {
            if (item.id == this.defaultId) this.selectedItem = item;
          })
          this.input();
        }
        this.$emit("items", this.items);
      }).finally(() => (this.isLoading = false));
    }
  }
}
